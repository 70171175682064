import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ScrollContext } from 'react-router-scroll-4';
import { IntlReducer as Intl, IntlProvider } from 'react-redux-multilingual'
import './index.scss';

// Import custom components
import store from './store';
import translations from './constants/translations'
import { getAllProducts } from './actions'


// // Layouts
// import Fashion from './components/layouts/fashion/main';
// import Vegetables from './components/layouts/vegetables/main';
// import Kids from './components/layouts/kids/main';
// import Pets from './components/layouts/pets/main';
import Furniture from './components/layouts/furniture/main';
// import Watch from './components/layouts/watch/main';
// import Beauty from './components/layouts/beauty/main';
// import Electronic from './components/layouts/electronic/main';


//Collection Pages
import CollectionLeftSidebar from "./components/collection/collection-left-sidebar";
import CollectionNoSidebar from "./components/collection/collection-no-sidebar";
import CollectionRightSidebar from "./components/collection/collection-right-sidebar";
import CollectionFullWidth from "./components/collection/collection-full-width";
import CollectionMetro from "./components/collection/collection-metro";

// Product Pages
import LeftSideBar from "./components/products/left-sidebar";

// Features
import Layout from './components/app'


class Root extends React.Component {
    
    render() {
        store.dispatch(getAllProducts());

        return(
        	<Provider store={store}>
                <IntlProvider translations={translations} locale='en'>
				<HashRouter basename={'/'} >
					<ScrollContext>
						<Switch>
                            <Route exact path={`/`} component={Furniture}/>
                            <Route path={`/furniture`} component={Furniture}/>
                            <Layout>
                                {/*Routes For Layouts*/}

								{/*Routes For Features (Product Collection) */}
								<Route path={`/left-sidebar/collection`} component={CollectionLeftSidebar}/>
								<Route path={`/no-sidebar/collection`} component={CollectionFullWidth}/>
								<Route path={`/right-sidebar/collection`} component={CollectionRightSidebar}/>
								<Route path={`/full-width/collection/:attribId`} component={CollectionFullWidth}/>
								<Route path={`/metro/collection`} component={CollectionMetro}/>

								{/*Routes For Single Product*/}
								<Route path={`/left-sidebar/product/:attribId/:id`} component={LeftSideBar}/>
			


                                {/* <Route exact path="*" component={PageNotFound} /> */}
                            </Layout>
                         </Switch>
					  </ScrollContext>
					</HashRouter>
                </IntlProvider>
			</Provider>
    	);
    }
}

ReactDOM.render(<Root />, document.getElementById('root'));


