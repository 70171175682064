import React, {Component} from 'react';
import { connect } from 'react-redux'
import {Link} from 'react-router-dom';

import {getVisibleproducts} from '../../services/index';
import ProductListItem from "./common/product-list-item";
import HeaderTwo from "../common/headers/header-two"

class CollectionFullWidth extends Component {
    constructor (props) {
        super (props)

        this.state = {
            limit: 5,
            hasMoreItems: false,
        };
    }
    async componentDidMount() {
        const id = this.props.match.params.attribId;
        const b = await fetch(`https://api.ashishhomes.com/getSubCategoryDetails?attrib=${id}`);
        const list = await b.json();
        this.setState({
            productList: list.data
        });
    }
    async componentDidUpdate(next, prev) {
        if (this.props.match.params.attribId !== next.match.params.attribId) {
            this.setState({
                productList: null,
            });
            const id = this.props.match.params.attribId;
            const b = await fetch(`https://api.ashishhomes.com/getSubCategoryDetails?attrib=${id}`);
            const list = await b.json();
            this.setState({
                productList: list.data
            });
        }
    }
    componentWillMount(){
        this.fetchMoreItems();
    }

    fetchMoreItems = () => {
        // if (this.state.limit >= this.props.products.length) {
        //     this.setState({ hasMoreItems: false });
        //     return;
        // }
        // a fake async api call
        // setTimeout(() => {
        //     this.setState({
        //         limit: this.state.limit + 5
        //     });
        // }, 3000);


    }

    render (){
        const { productList } = this.state;
        if (!productList) return (<div>
                {/* <Breadcrumb title={'Collection'}/> */}
                {/* <HeaderTwo logoName={'logo/1.png'} categories={this.state.categories} /> */}
                <div style={{height: '400px'}}>
                    {/* <div className="row"> */}
                        {/* <div className="col-12"> */}
                            <div className="loading-cls" style={{ position: 'relative', marginTop: '40px'}}>
                            </div>
                        {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
            );
        
        return (
            <div>
                {/* <Breadcrumb title={'Collection'}/> */}
                {/* <HeaderTwo logoName={'logo/1.png'} categories={this.state.categories} /> */}

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="section-t-space portfolio-section portfolio-padding metro-section port-col">
                                {productList.length > 0 ?
                                    // <InfiniteScroll
                                    //     dataLength={this.state.limit} //This is important field to render the next data
                                    //     next={this.fetchMoreItems}
                                    //     hasMore={this.state.hasMoreItems}
                                    //     loader={<div className="loading-cls"></div>}
                                    //     endMessage={
                                    //         <p className="seen-cls seen-it-cls">
                                    //             <b>Yay! You have seen it all</b>
                                    //         </p>
                                    //     }
                                    // >
                                        <div className="isotopeContainer row">
                                            { productList.map((product, index) =>
                                                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 isotopeSelector" key={index}>
                                                    <ProductListItem
                                                        product={product}
                                                        key={index}
                                                        id={this.props.match.params.attribId}
                                                    />
                                                </div>)
                                            }
                                        </div>
                                    // </InfiniteScroll>
                                    :
                                    <div className="row">
                                        <div className="col-sm-12 text-center section-b-space mt-5 no-found" >
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} class="img-fluid mb-4" />
                                            <h3>Sorry! Couldn't find the product you were looking For!!!    </h3>
                                            <p>Please check if you have misspelt something or try searching with other words.</p>
                                            <Link to={`${process.env.PUBLIC_URL}/`} class="btn btn-solid">continue shopping</Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    products: getVisibleproducts(state.data, state.filters),
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps, null
)(CollectionFullWidth)