import React, { Component } from 'react';
import {connect} from 'react-redux'

import { getNewProducts } from '../../../services/index'
import ProductItem from '../common/product-item';

class SpecialProducts extends Component {
    state=  {}
    async componentDidMount() {
        const data = await fetch('https://api.ashishhomes.com/getTrendingCategory');
        const products = await data.json();
        this.setState({ newProducts: products.data })
    }
    render (){
        const { newProducts } = this.state;
        if (!this.state.newProducts) return null;
        return (
            <div>
                <div className="title1 section-t-space">
                    <h2 className="title-inner1">our products</h2>
                </div>
                <section className="section-b-space p-t-0">
                    <div className="container">
                        <div className="no-slider row">
                            { newProducts.map((product, index ) =>
                                <ProductItem
                                    product={product}
                                    key={index}
                                />)
                            }
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    newProducts: getNewProducts(state.data.products, ownProps.type),
})

export default connect(mapStateToProps, null) (SpecialProducts);