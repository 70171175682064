import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Slider from 'react-slick';
import '../common/index.scss';
import {connect} from "react-redux";

// import custom Components
import DetailsWithPrice from "./common/product/details-price";
import { addToCart, addToCartUnsafe, addToWishlist } from '../../actions'
import ImageZoom from './common/product/image-zoom'
import SmallImages from './common/product/small-image'
import HeaderTwo from "../common/headers/header-two"



class LeftSideBar extends Component {

    constructor() {
        super();
        this.state = {
            open:false,
            nav1: null,
            nav2: null
        };
    }

    // document.getElementById('idOfElement').classList.add('newClassName');
    async componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color5.css` );
        const { id, attribId } = this.props.match.params;
        const b = await fetch(`https://api.ashishhomes.com/getSubCategoryDetails?attrib=${attribId}`);
        const list = await b.json();
        this.setState({
            productList: list.data.find(item => item.sku === id),
        });
    }
    
    filterClick() {
        document.getElementById("filter").style.left = "-15px";
    }
    backClick() {
        document.getElementById("filter").style.left = "-365px";
    }

    render(){
        const {symbol, item = {}} = this.props
        var products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true
        };
        var productsnav = {
            slidesToShow: 2,
            swipeToSlide:true,
            arrows: true,
            dots: false,
            focusOnSelect: true
        }; 
        const { productList } = this.state;
        if (!productList) return (<div>
                {/* <Breadcrumb title={'Collection'}/> */}
                {/* <HeaderTwo logoName={'logo/1.png'} categories={this.state.categories} /> */}
                <div style={{height: '400px'}}>
                    {/* <div className="row"> */}
                        {/* <div className="col-12"> */}
                            <div className="loading-cls" style={{ position: 'relative', marginTop: '40px'}}>
                            </div>
                        {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
            );
        // return null;
        return (
            <div >
                {/*SEO Support*/}
                <Helmet>
                    {/* <title>MultiKart | {item.category} | {item.name}</title> */}
                    <meta name="description" content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses." />
                </Helmet>
                {/*SEO Support End */}

                {/* <Breadcrumb  parent={'Product'} title={item.name} /> */}
                {/* <HeaderTwo logoName={'logo/1.png'} categories={this.state.categories} /> */}

                {/*Section Start*/}
                {(productList)?
                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9 col-sm-12 col-xs-12">
                                    <div className="">
                                        {/* <div className="row">
                                            <div className="col-xl-12">
                                                <div className="filter-main-btn mb-2">
                                                    <span onClick={this.filterClick}  className="filter-btn" >
                                                        <i className="fa fa-filter" aria-hidden="true"></i> filter</span>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="row">
                                            <div className="col-lg-6 product-thumbnail">
                                                <Slider {...products} asNavFor={this.state.nav2} ref={slider => (this.slider1 = slider)} className="product-slick">
                                                    {productList.image.map((vari, index) =>
                                                       <div key={index}>
                                                           <ImageZoom image={vari} />
                                                       </div>
                                                    )}
                                                </Slider>
                                                {/* <SmallImages item={productList} settings={productsnav} navOne={this.state.nav1} /> */}
                                            </div>
                                            <DetailsWithPrice sku={this.props.match.params.id}symbol={symbol} item={productList} navOne={this.state.nav1} />
                                        </div>
                                    </div>
                                    {/* <DetailsTopTabs item={item} /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section> : ''}
                {/*Section End*/}
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.id;
    return {
        item: state.data.products.find(el => el.id == productId),
        symbol: state.data.symbol
    }
}

export default connect(mapStateToProps, {addToCart, addToCartUnsafe, addToWishlist}) (LeftSideBar);