import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class ProductItem extends Component {
    constructor(props){
        super(props)
        this.state = {
            image: ''
        }
    }

    onClickHandle(img) {
        this.setState({ image : img} );
    }


    render() {
        const { product } = this.props;
        return (
            <div className="product-box">
                <div className="img-wrapper">
                    <div className="front">
                        <Link to={`/full-width/collection/${product.attrib}`} ><img
                            src={product.image}
                            className="img-fluid"
                            alt="" /></Link>
                    </div>
                </div>
                <div className="product-detail">
                    <div style= {{ textAlign: 'center', marginTop: '10px' }}>
                        <Link to={`/full-width/collection/${product.attrib}`}>
                            <h6>{product.subcategory_name}</h6>
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default ProductItem;