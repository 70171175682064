import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import Slider from 'react-slick';
import Modal from 'react-responsive-modal';


class DetailsWithPrice extends Component {

    constructor (props) {
        super (props)
        this.state = {
            open:false,
            quantity:1,
            stock: 'InStock',
            nav3: null
        }
    }

    onOpenModal = () => {
        this.setState({ open: true });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });
    }

    minusQty = () => {
        if(this.state.quantity > 1) {
            this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        if(this.props.item.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity+1})
        }else{
            this.setState({stock: 'Out of Stock !'})
        }
    }
    changeQty = (e) => {
        this.setState({ quantity: parseInt(e.target.value) })
    }

    render (){
        const { item } = this.props

        var colorsnav = {
            slidesToShow: 6,
            swipeToSlide:true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };

        return (
            <div className="col-lg-6 rtl-text">
                <div className="product-right">
                    <h2> {item.name} </h2>
                    {/* <h4>
                        <del>{symbol}{item.price}</del>
                        <span>{item.discount}% off</span></h4> */}
                    {/* <h3>{symbol}{item.price-(item.price*item.discount/100)} </h3> */}
                    {item.variants?
                    <ul >
                        <Slider {...colorsnav} asNavFor={this.props.navOne} ref={slider => (this.slider1 = slider)} className="color-variant">
                            {item.variants.map((vari, i) => {
                                return <li className={vari.color} key={i} title={vari.color}></li>
                            })}
                        </Slider>
                    </ul>:''}
                    <div className="border-product">
                        <h6 className="product-title">SKU</h6>
                        <p>{this.props.sku}</p>
                    </div>
                    <div className="border-product">
                        <h6 className="product-title">PRODUCT DETAILS</h6>
                        <p>
                        <table className="table table-striped mb-0" style={{ textTransform: 'uppercase' }}>
                                    <tbody>
                                        <tr>
                                            <th>TITLE</th>
                                            <td>{item.title}</td>
                                        </tr>
                                        <tr>
                                            <th>WOOD</th>
                                            <td>{item.wood}</td>
                                        </tr>
                                        <tr>
                                            <th>SIZE</th>
                                            <td>{item.size}</td>
                                        </tr>
                                        <tr>
                                            <th>FINISH</th>
                                            <td>{item.finish}</td>
                                        </tr>
                                    </tbody>
                                </table>
                        </p>
                    </div>
                </div>
                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Sheer Straight Kurta</h5>
                            </div>
                            <div className="modal-body">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}


export default DetailsWithPrice;