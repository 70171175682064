import React, {Component} from 'react';
import { withTranslate } from 'react-redux-multilingual'

// Custom Components
// import HeaderOne from './common/headers/header-one';
import HeaderTwo from './common/headers/header-two';
// import HeaderThree from './common/headers/header-three';

import FooterOne from "./common/footers/footer-one";
// import FooterTwo from "./common/footers/footer-two";
// import FooterThree from "./common/footers/footer-three";

// ThemeSettings



class App extends Component {
    state = {}
    async componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color5.css` );
        const a = await fetch('https://api.ashishhomes.com/getProductCategory');
        const categories= await a.json();
        this.setState({
            categories: categories.data
        });
    }
    render() {
        return (
            <div>
                {<HeaderTwo logoName={'logo/1.png'} categories={this.state.categories} />}
                {this.props.children}
                <FooterOne logoName={'logo.png'}/>
            </div>
        );
    }
}

export default withTranslate(App);
