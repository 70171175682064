import React, { Component } from 'react';
import {Helmet} from 'react-helmet'
import '../../common/index.scss';
import Slider from 'react-slick';

// Import custom components
import SpecialProducts from "./special-products"
import HeaderOne from "../../common/headers/header-two"
import FooterOne from "../../common/footers/footer-one";

class Furniture extends Component {
    state = {}
    async componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color5.css` );
        const a = await fetch('https://api.ashishhomes.com/getProductCategory');
        const categories= await a.json();
        this.setState({
            categories: categories.data
        });
    }

    render(){
        return (
            <div>
            <HeaderOne logoName={'logo/1.png'} categories={this.state.categories} />
            <Helmet>
                <meta name="description" content="Descition about Ashsih Homes" />
            </Helmet>
            <section className="p-0 small-slider">
                <Slider className="slide-1 home-slider">
                    <div>
                        <div className="home home12 text-left">
                        </div>
                    </div>
                    {/* <div>
                        <div className="home home13 text-left">
                        </div>
                    </div> */}
                </Slider>
            </section>
            <SpecialProducts type={'furniture'} />
            {/* <ThemeSettings/> */}
            <FooterOne logoName={'logo/1.png'} />
        </div>
        )
    }
}


export default Furniture;