import React, {Component} from 'react';
import { Link} from 'react-router-dom';

import {SlideUpDown} from "../../../services/script"
import LogoImage from "../headers/common/logo"

class FooterOne extends Component {

    componentDidMount(){
        var contentwidth = window.innerWidth;
        if ((contentwidth) < 750) {
            SlideUpDown('footer-title');
        } else {
            var elems = document.querySelectorAll(".footer-title");
            [].forEach.call(elems, function(elemt) {
                let el = elemt.nextElementSibling;
                el.style = "display: block";
            });
        }
    }


    render () {
        return (
            <footer className="footer-light">
                <section className="section-b-space light-layout">
                    <div className="container">
                        <div className="row footer-theme partition-f" style={{ justifyContent: 'space-between'}}>
                            <div className="col-lg-4">
                                <div className="footer-contant">
                                    <div className="footer-logo">
                                        <LogoImage logo="logo/1.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 ">
                                <div className="sub-title">
                                    <h4>Contact Us</h4>
                                    <div className="footer-contant">
                                        <ul className="contact-list">
                                            <li><i className="fa fa-map-marker"></i>Behind Old RIICO, Bagru, Jaipur, Rajasthan
                                            </li>
                                            <li><i className="fa fa-phone"></i>Call Us: +91-9799808906</li>
                                            <li><i className="fa fa-envelope-o"></i>Email Us: <span style={{ textTransform: 'none'}}
                                                href="#">ashishhomes01@gmail.com</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </footer>
        )
    }
}

export default FooterOne;