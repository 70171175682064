import React, {Component} from 'react';
import {Link} from 'react-router-dom';

class ProductListItem extends Component {

    constructor(props){
        super(props)
        this.state = {
            quantity: 1,
            image: ''
        }
    }

    render() {
        const {product, id } = this.props;        
        return (
            <div className="product-box">
                <div className="img-wrapper">
                    <div className="front">
                        <Link to={`/left-sidebar/product/${id}/${product.sku}`} ><img
                            src={product.image[0]}
                            className="img-fluid"
                            alt="" /></Link>
                    </div>
                </div>
        </div>
        )
    }
}

export default ProductListItem;